import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://api.aylix.world',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Origin': "https://aylix.world",
  },
});

export default axiosInstance;
