import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Style.css';
import axiosInstance from '../axiosInstance';
function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    console.log(username, password);

    try {
      const response = await axiosInstance.post('/login', { username, password });
      setToken(response.data.token);
      setMessage('Login successful!');
      navigate('/chatbot');
     } catch (error) {
      if (error.response && error.response.data) {
        setMessage(error.response.data.detail || "Login failed");
      } else {
        setMessage("An error occurred. Please try again.");
      }
    }
  };

  return (
    <div className="container">
      <img src="/images/AYLIX.png" className="logo" alt="Aylix Logo" />
      <h2>Sign in</h2>
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleLogin}>Login</button>
      <p className="message">{message}</p>
      {token && <p>Token: {token}</p>}
      <p>Don't have an account? <Link to="/register">Register here</Link></p>
      <p>Forgot your password? <Link to="/forgot-password">Reset it here</Link></p>
    </div>
  );
}

export default Login;
