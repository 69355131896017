import React, { useState } from 'react';
import './main.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faHurricane } from '@fortawesome/free-solid-svg-icons';
import axiosInstance from '../../axiosInstance';

const Main = ({ username, onVectorsUpdate }) => {
  const [query, setQuery] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSearch = async () => {
    if (query.trim() === '') {
      setResponse('Please enter a question!');
      return;
    }

    setLoading(true);
    try {
      await axiosInstance.post('/update-vectors');
      console.log("Vectors updated successfully");

      const result = await axiosInstance.post('/ask-bot', { query: query });
      setResponse(result.data.response || 'No data found');
    } catch (error) {
      console.error('Error in handleSearch:', error.response ? error.response.data : error);
      setResponse('Error: Unable to fetch data');
    } finally {
      setLoading(false);
    }
  };
  
  const handleCardClick = (section) => {
    console.log(`${section} clicked`);
  };

  return (
    <main>
      <img src="/images/AYLIX.png" className="logo" alt="Aylix Logo" />

      {query === '' && (
        <div className="cards">
          <div className="card blue" onClick={() => handleCardClick('Sales Data Insights')}>
            <h2>SALES DATA INSIGHTS</h2>
            <p>Emphasizes Sales Trend</p>
            <span>Soaring</span>
            <div className="date">Yet to Upload</div>
          </div>
          <div className="card purple" onClick={() => handleCardClick('Growth Engine Analytics')}>
            <h2>GROWTH ENGINE ANALYTICS</h2>
            <p>Focuses on Outcome</p>
            <span>Converting</span>
            <div className="date">Yet to Upload</div>
          </div>
          <div className="card orange" onClick={() => handleCardClick('Procurement Sourcing Insights')}>
            <h2>PROCUREMENT SOURCING INSIGHTS</h2>
            <p>Optimize Supply Chain</p>
            <span>Strengthening</span>
            <div className="date">Yet to Upload</div>
          </div>
          <div className="card yellow" onClick={() => handleCardClick('Customer Success Metrics')}>
            <h2>CUSTOMER SUCCESS METRICS</h2>
            <p>Empower customer satisfaction</p>
            <span>Growing</span>
            <div className="date">Yet to Upload</div>
          </div>
        </div>
      )}
      <div className="fixed-bottom">
        {response && (
          <div className="response-container">
            <p>
              {response.split('\n').map((line, index) => (
                <span key={index}>{line}<br /></span>
              ))}
            </p>
          </div>
        )}
        {loading && (
          <div className="loading-icon">
            <FontAwesomeIcon icon={faHurricane} spin size="2x" />
          </div>
        )}
        <div className="search-bar">
          <input
            type="text"
            placeholder="Ask me anything about your data!"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            disabled={loading}
          />
          <button className="send-icon" onClick={handleSearch} disabled={loading}>
            <FontAwesomeIcon icon={faPaperPlane} />
          </button>
        </div>
      </div>
    </main>
  );
};

export default Main;
