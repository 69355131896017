import React, { useState } from 'react';
import './Style.css';
import axiosInstance from '../axiosInstance';

function ForgotPassword() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleForgotPassword = async () => {
    try {
      const response = await axiosInstance.post('/forgot-password', { username });
      setMessage(response.data.message);
    } catch (error) {
      setMessage(error.response.data.error);
    }
  };

  return (
    <div className="container">
      <img src="/images/AYLIX.png" className="logo" alt="Aylix Logo" />
      <h2>Forgot Password</h2>
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        type="text"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <button onClick={handleForgotPassword}>Request Password Reset</button>
      <p className="message">{message}</p>
    </div>
  );
}

export default ForgotPassword;
